/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon } from "@aws-amplify/ui-react";
export default function IconAddsec(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M13 2.268L13 10C13 10.7957 12.6839 11.5587 12.1213 12.1213C11.5587 12.6839 10.7957 13 10 13L2.268 13C2.44353 13.304 2.696 13.5565 3.00003 13.732C3.30406 13.9076 3.64894 14 4 14L10 14C11.0609 14 12.0783 13.5786 12.8284 12.8284C13.5786 12.0783 14 11.0609 14 10L14 4C14 3.64894 13.9076 3.30406 13.732 3.00003C13.5565 2.696 13.304 2.44353 13 2.268L13 2.268ZM8.5 6.5C8.63261 6.5 8.75979 6.44732 8.85355 6.35355C8.94732 6.25979 9 6.13261 9 6C9 5.86739 8.94732 5.74022 8.85355 5.64645C8.75979 5.55268 8.63261 5.5 8.5 5.5L6.5 5.5L6.5 3.5C6.5 3.36739 6.44732 3.24022 6.35355 3.14645C6.25979 3.05268 6.13261 3 6 3C5.86739 3 5.74022 3.05268 5.64645 3.14645C5.55268 3.24022 5.5 3.36739 5.5 3.5L5.5 5.5L3.5 5.5C3.36739 5.5 3.24022 5.55268 3.14645 5.64645C3.05268 5.74022 3 5.86739 3 6C3 6.13261 3.05268 6.25979 3.14645 6.35355C3.24022 6.44732 3.36739 6.5 3.5 6.5L5.5 6.5L5.5 8.5C5.5 8.63261 5.55268 8.75979 5.64645 8.85355C5.74022 8.94732 5.86739 9 6 9C6.13261 9 6.25979 8.94732 6.35355 8.85355C6.44732 8.75979 6.5 8.63261 6.5 8.5L6.5 6.5L8.5 6.5ZM10 0C10.5304 4.44089e-16 11.0391 0.210714 11.4142 0.585786C11.7893 0.960859 12 1.46957 12 2L12 10C12 10.5304 11.7893 11.0391 11.4142 11.4142C11.0391 11.7893 10.5304 12 10 12L2 12C1.46957 12 0.960859 11.7893 0.585786 11.4142C0.210714 11.0391 4.44089e-16 10.5304 0 10L0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 4.44089e-16 2 0L10 0ZM11 2C11 1.73478 10.8946 1.48043 10.7071 1.29289C10.5196 1.10536 10.2652 1 10 1L2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2L1 10C1 10.2652 1.10536 10.5196 1.29289 10.7071C1.48043 10.8946 1.73478 11 2 11L10 11C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10L11 2Z",
          fill: "rgba(0,75,133,1)",
          fillRule: "nonzero",
          style: { transform: "translate(15%, 15%)" },
        },
      ]}
      {...getOverrideProps(overrides, "IconAddsec")}
      {...rest}
    ></Icon>
  );
}
